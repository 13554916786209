import { DownOutlined } from "@ant-design/icons"
import React from "react"
import "../styles/variables.scss"
import useScreenSize from "../utils/useScreenSize"

export default function ScrollDown({ arrowClassName, bigScreenOnly }) {
  const isSmallScreen = useScreenSize()

  return (
    <div className="scroll-down-container">
      {(!bigScreenOnly || (bigScreenOnly && !isSmallScreen)) && (
        <DownOutlined title="Scroll down!" className={arrowClassName} />
      )}
    </div>
  )
}
