import React from "react"
import ConditionalWrapper from "./conditional-wrapper"
import ResponsiveImage from "./responsive-image"

export default function ResponsiveImageGrid({
  grid,
  unified,
  gridClass = "",
  margin,
  partsWeights,
  orientation = "horizontal",
  description,
  descriptionColor,
  text,
  textElement,
  textContainerClass = "paragraph-div-centered-absolutely",
  textClass,
  rounded = true
}) {
  gridClass += unified ? " grid-container-unified" : " grid-container"
  gridClass += margin ? " grid-margin" : ""
  gridClass += description ? " container-above-description-container" : ""

  const gridTemplate = partsWeights?.reduce(
    (style, weight) => `${style} ${weight}%`,
    ""
  )

  return (
    <ConditionalWrapper
      condition={description}
      wrapper={children => (
        <div className="container-with-description-container">
          {children}
          <div className="description-container">
            <p className="img-description" style={{ color: descriptionColor }}>
              {description}
            </p>
          </div>
        </div>
      )}
    >
      <div
        className={gridClass}
        style={{
          gridTemplateColumns: orientation === "horizontal" && gridTemplate,
          gridTemplateRows: orientation === "vertical" && gridTemplate
        }}
      >
        {grid.map((gridPart, i) => {
          let part = undefined

          if (gridPart.type === "grid") {
            const subPartsWeights = gridPart.grid.map(p => p.weight)
            part = (
              <ResponsiveImageGrid
                rounded={
                  gridPart.rounded !== undefined ? gridPart.rounded : rounded
                }
                grid={gridPart.grid}
                partsWeights={subPartsWeights}
                margin={gridPart.margin}
                orientation={gridPart.orientation}
                description={gridPart.description}
                descriptionColor={gridPart.descriptionColor}
                totalWeight={
                  ((gridPart.type === "image" ||
                    (gridPart.type === "grid" &&
                      gridPart.orientation === orientation)) &&
                    gridPart.weight) ||
                  100
                }
                unified={gridPart.unified || unified}
                text={gridPart.text}
                textElement={gridPart.textElement}
                textContainerClass={gridPart.textContainerClass}
                textClass={gridPart.textClass}
                gridClass={gridPart.gridClass}
              />
            )
          } else if (gridPart.type === "image") {
            const { type, weight, ...imageProps } = gridPart
            part = <ResponsiveImage rounded={rounded} {...imageProps} />
          } else if (gridPart.type === "html") {
            part = gridPart.html
          }

          return part
        })}
        {text && (
          <div className={textContainerClass}>
            <p className={textClass}>{text}</p>
          </div>
        )}
        {textElement}
      </div>
    </ConditionalWrapper>
  )
}
