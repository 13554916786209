import React from "react"
import Page from "../../../components/page"
import ResponsiveImageGrid from "../../../components/responsive-image-grid"
import ScrollDown from "../../../components/scroll-down"
import { themes } from "../../../styles/themes"
import paths from "../../../utils/paths"
import useScreenSize from "../../../utils/useScreenSize"

const flirtWithArtGrid = (labels, isSmallScreen) => [
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src:
          "/images/flirt-with-art/x-armchair-from-furniture-to-architecture.jpg",
        title: labels.flirtWithArtXArmchairFromFurnitureToArchitectureTitle,
        titleContainerClass:
          "flirt-with-art-from-furniture-to-architecture-title-container",
        titleClass: "flirt-with-art-from-furniture-to-architecture-title",
        alt: labels.flirtWithArtXArmchairAlt,
        imgTitle: labels.xArmchairImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    unified: isSmallScreen,
    grid: isSmallScreen
      ? [
          {
            type: "image",
            weight: 49.25,
            src: "/images/flirt-with-art/deep-throat-flirt-with-art-1.jpg",
            alt: labels.flirtWithArtDeepThroatAlt,
            imgTitle: labels.deepThroatImgTitle
          },
          {
            type: "image",
            weight: 49.25,
            src: "/images/flirt-with-art/deep-throat-flirt-with-art-2.jpg",
            alt: labels.flirtWithArtDeepThroatAlt,
            imgTitle: labels.deepThroatImgTitle
          }
        ]
      : [
          {
            type: "image",
            weight: 32.33,
            src: "/images/flirt-with-art/deep-throat-flirt-with-art-1.jpg",
            alt: labels.flirtWithArtDeepThroatAlt,
            imgTitle: labels.deepThroatImgTitle
          },
          {
            type: "image",
            weight: 32.33,
            src: "/images/flirt-with-art/deep-throat-flirt-with-art-2.jpg",
            alt: labels.flirtWithArtDeepThroatAlt,
            imgTitle: labels.deepThroatImgTitle
          },
          {
            type: "image",
            weight: 32.33,
            src: "/images/flirt-with-art/deep-throat-flirt-with-art-3.jpg",
            alt: labels.flirtWithArtDeepThroatAlt,
            imgTitle: labels.deepThroatImgTitle
          }
        ]
  },
  isSmallScreen
    ? {
        type: "grid",
        orientation: "horizontal",
        margin: true,
        grid: [
          {
            type: "image",
            weight: 100,
            src: "/images/flirt-with-art/deep-throat-flirt-with-art-3.jpg",
            alt: labels.flirtWithArtDeepThroatAlt,
            imgTitle: labels.deepThroatImgTitle
          }
        ]
      }
    : {},
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "html",
        weight: 100,
        html: (
          <div className="flirt-with-art-flirts-text-container">
            <p className="flirt-with-art-flirts-text-paragraph">
              {labels.flirtWithArtFlirtsAreLikeMoviesText}
            </p>
            <p className="flirt-with-art-flirts-author-paragraph">
              {labels.flirtWithArtFlirtsAreLikeMoviesAuthor}
            </p>
          </div>
        )
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "grid",
        orientation: "vertical",
        weight: 56,
        grid: [
          {
            type: "image",
            weight: 49.5,
            src: "/images/flirt-with-art/mustang-upside-down.jpg",
            alt: labels.flirtWithArtMustangAlt,
            imgTitle: labels.mustangImgTitle
          },
          {
            type: "image",
            weight: 49.25,
            src: "/images/flirt-with-art/strip-poker-table.jpg",
            title: labels.flirtWithArtStripPokerTableTitle,
            alt: labels.flirtWithArtStripPokerTableAlt,
            imgTitle: labels.stripPokerTableImgTitle
          }
        ]
      },
      {
        type: "image",
        weight: 42.5,
        src: "/images/flirt-with-art/deep-throat-body-leg-detail.jpg",
        alt: labels.flirtWithArtDeepThroatBodyAlt,
        imgTitle: labels.deepThroatImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 35,
        src: "/images/flirt-with-art/memories-chair.jpg",
        alt: labels.flirtWithArtMemoriesChairAlt,
        imgTitle: labels.memoriesChairImgTitle
      },
      {
        type: "html",
        weight: 27,
        html: (
          <div className="paragraph-div-centered-relatively flirt-with-art-stylistic-metamorphoses-text-container">
            <p>{labels.flirtWithArtStylisticMetamorphoses}</p>
          </div>
        )
      },
      {
        type: "image",
        weight: 35,
        src: "/images/flirt-with-art/coco-barocco-detail.jpg",
        title: labels.flirtWithArtCocoBaroccoTitle,
        alt: labels.flirtWithArtCocoBaroccoAlt,
        imgTitle: labels.cocoBaroccoImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/flirt-with-art/mens-secrets-armchair-neon.jpg",
        alt: labels.flirtWithArtMensSecretsAlt,
        imgTitle: labels.mensSecretsImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "html",
        weight: 100,
        html: (
          <div className="paragraph-div-centered-relatively flirt-with-art-artistic-signature-text-container">
            <p>{labels.flirtWithArtArtisticSignature}</p>
          </div>
        )
      }
    ]
  },

  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/flirt-with-art/xxx-commode-detail.jpg",
        title: labels.flirtWithArtXXXCommodeTitle,
        text: isSmallScreen ? undefined : labels.flirtWithArtArtisticSignature,
        textClass: "flirt-with-art-artistic-signature-text",
        alt: labels.flirtWithArtXXXCommodeAlt,
        imgTitle: labels.xxxCommodeImgTitle
      }
    ]
  }
]

export default function FlirtWithArt({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  return (
    <Page
      {...props}
      labels={labels}
      title={labels.flirtWithArt}
      theme={themes(labels).ipsBlack(true)}
      description={labels.flirtWithArtDescription}
      keywords={labels.flirtWithArtKeywords}
      ogUrl={paths(labels).flirtWithArt}
      ogImage="/images/og-images/flirt-with-art.jpg"
      footerPadding
    >
      <ScrollDown bigScreenOnly={true} />
      <ResponsiveImageGrid grid={flirtWithArtGrid(labels, isSmallScreen)} />
    </Page>
  )
}
